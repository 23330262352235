<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <DataTable :apiUrl = "'/tagsData/data/get-data?tag_id=' + this.$route.params.tag_id" />
  </Layout>
</template>

<script>
import DataTable from "@/components/DataTable"
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import {tagsService} from "@/helpers/fakebackend/tags.service";

export default {
  name: "DataTags",
  components: { Layout, DataTable, PageHeader },
  data: function () {
    return {
      title: "",
      items: [
        {
          text: this.$t('General.Breadcrumbs.Main'),
          href: "/",
        },
        {
          text: this.$t('General.Breadcrumbs.UserData'),
          href: "/data",
        },
        {
          text: "",
          active: true,
        },
      ]
    }
  },
  methods: {
    ...tagsService,
    loadTag() {
      this.getTag({id: this.$route.params.tag_id}).then(result => {
        this.title = 'Data: №' + result.id + ' ' + result.name
        this.items[2].text = result.name
      })
    }
  },
  mounted() {
    if (this.$route.params.tag_id === undefined) {
      this.$router.push('/error-404')
    }
    this.loadTag()
  }
}
</script>

<style scoped>

</style>