<template>
  <div>
    <div >
      <b-row align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-dropdown variant="primary">
            <template slot="button-content">
              {{$t('Components.DataTable.Columns')}}
              <i class="mdi mdi-chevron-down"></i>
            </template>

            <b-dropdown-form>
              <b-form-group
                  v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                    v-model="activeFields"
                    :options="fieldsToOptions"
                    :aria-describedby="ariaDescribedby"
                    switches
                    stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </b-dropdown-form>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-table
          @sort-changed="sortingChanged"
          :items="items"
          :fields="fieldsToTable"
          :busy="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          striped
          hover
          show-empty
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

<!--        <template #row-details="row">-->
<!--          <b-card>-->
<!--            <b-row class="mb-2">-->
<!--              <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>-->
<!--              <b-col>{{ row.item.age }}</b-col>-->
<!--            </b-row>-->

<!--            <b-row class="mb-2">-->
<!--              <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>-->
<!--              <b-col>{{ row.item.isActive }}</b-col>-->
<!--            </b-row>-->

<!--            <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>-->
<!--          </b-card>-->
<!--        </template>-->

      </b-table>

      <b-row align-h="between">
        <b-col cols="auto">
          <b-pagination
              class="mt-2 ml-auto mr-auto"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="10"
          ></b-pagination>
        </b-col>
        <b-col cols="auto">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="6"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import ApiBack from "@/helpers/fakebackend/api";

export default {
  name: "DataTable",
  props: ["startFields", "apiUrl"],
  data: function () {
    return {
      isBusy: true,
      activeFields: [],
      sortBy: 'checkout_id',
      sortDesc: true,
      currentPage: 1,
      totalRows: 0,
      perPage: 20,
      fields: [],
      items: [],
      pageOptions: [20, 50, 100],
    }
  },
  computed: {
    fieldsToOptions: function() {
      let options = []
      this.fields.forEach(elem => {
        if (elem.label) {
          elem.label += ' (' + elem.key + ')'
        }
        options.push({
          text: elem.label === undefined ? elem.key : elem.label,
          value: elem.key
        })
      })
      return options
    },
    fieldsToTable: function() {
      let cols = []
      this.fields.forEach(elem => {
        if (this.activeFields.includes(elem.key)) {
          cols.push(elem)
        }
      })
      return cols
    }
  },
  watch: {
    currentPage: function() {
      this.getDataFromServer()
    },
    perPage: function() {
      this.getDataFromServer()
    }
  },
  methods: {
    onFields() {
      if (this.startFields === undefined || this.startFields === null || this.startFields === 'all') {
        this.setAllFields()
      }
    },
    setAllFields() {
      this.activeFields = []
      this.fields.forEach(elem => {
        this.activeFields.push(elem.key)
      })
    },
    sortingChanged() {
      this.getDataFromServer()
      return false
      // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
      // ctx.sortDesc ==> true if sorting descending, false otherwise
    },
    getDataFromServer() {
      this.isBusy = true
      const request = {
        method: 'get',
        path: this.apiUrl,
        params: {
          limit: this.perPage,
          offset: this.currentPage,
          sort_field: this.sortBy,
          sort: this.sortDesc
        }
      }

      new ApiBack().sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
          if (response.data.status === 'success') {
            this.fields = response.data.data.fields
            this.items = response.data.data.rows
            this.totalRows = response.data.data.totalRow
            this.onFields()
            this.isBusy = false
            // resolve(response.data.data)
            // console.log(response)
          }
          if (response.data.status === 'error') {
            reject(response.data.data)
          }
        });
      })
    }
  },
  mounted() {
    if (this.apiUrl) {
      this.getDataFromServer()
    }

  }
}
</script>

<style scoped>

</style>