import { render, staticRenderFns } from "./DataTags.vue?vue&type=template&id=89da4b02&scoped=true&"
import script from "./DataTags.vue?vue&type=script&lang=js&"
export * from "./DataTags.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89da4b02",
  null
  
)

export default component.exports